<mat-dialog-content class="accent-color modal" style="width: 50rem; max-width: 100%; top: 0;">
  <h3>
    {{'override' | cfgLabel}}
  </h3>
  <button id="optionOverrideCloseButton" tabindex="-1" class="close-button" mat-dialog-close>×</button>

  <label [attr.for]="choice.id" *ngIf="descriptionExists()">
    {{'description' | cfgLabel}}:
    <div class="row align-middle">
      <div class="columns">
        <div class="mat-align">
          <mat-form-field floatLabel="never">
            <textarea matInput [id]="choice.id+'-description'" [(ngModel)]="description"
                      [disabled]="!overrideDescriptionPrivilege || !choice.overrideDesc" class="expand" #overrideDescription>
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </label>
  <div class="row align-middle">
    <label [attr.for]="choice.id" *ngIf="choice.price && viewListPrice" style="width: 50%">
      {{'enterBasePrice' | cfgLabel}}:
      <div class="columns">
        <div class="mat-align">
          <div class="o-input-select-combo">
            <mat-form-field floatLabel="never">
              <div matPrefix [ngClass]="{'disabled-appearance': !overridePricePrivilege}">{{choice.currency}}</div>
              <input matInput [id]="choice.id+'-price'" placeholder="{{choice.price | cfgLabel}}" [(ngModel)]="price"
                     [disabled]="!overridePricePrivilege" class="expand" #overridePrice>
              <div *ngIf="choice.sellMultiplier != 1.0 && isEditablePriceType(choice)" style="font-style: italic" matSuffix>
                ({{'multiplier' | cfgLabel}}: {{choice.sellMultiplier}})
              </div>
              <mat-hint *ngIf="choice.priceOverridden" style="color: black">{{'priceOverriddenByUser' | cfgLabel}}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </label>
    <label [attr.for]="choice.id" style="width: 50%" *ngIf="overrideQuantity">
      {{'quantity' | cfgLabel}}:
      <div class="columns">
        <div class="mat-align">
          <div class="o-input-select-combo" style="width: 100%">
            <mat-form-field floatLabel="never">
              <div matPrefix></div>
              <input matInput [id]="choice.id+'-quantity'" [placeholder]="choice.quantity" [(ngModel)]="quantity"
                     [disabled]="!choice.quantityEditable || !isSelected(choice)" class="expand" #overrideQuantity>
              <mat-hint align="start" *ngIf="choice.quantityEditable && !isSelected(choice)" style="color:red">
                Option must be selected to edit quantity
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </label>
  </div>
  <div class="row align-middle">
    <label [attr.for]="choice.id" style="width: 50%" *ngIf="overrideLeadTime">
      {{'leadTime' | cfgLabel}}:
      <div class="columns">
        <div class="mat-align">
          <div class="o-input-select-combo" style="width: 100%">
            <mat-form-field floatLabel="never">
              <div matPrefix></div>
              <input matInput [id]="choice.id+'-leadTime'" [(ngModel)]="leadTime"
                     [disabled]="!overridePricePrivilege" class="expand" #overrideLeadTime>
            </mat-form-field>
          </div>
        </div>
      </div>
    </label>

    <label [attr.for]="choice.id" style="width: 50%" *ngIf="overrideSource">
      {{'Source' | cfgLabel}}:
      <div class="columns">
        <div class="mat-align">
          <div class="o-input-select-combo" style="width: 100%">
            <mat-form-field floatLabel="never">
              <div matPrefix></div>
              <textarea matInput [id]="choice.id+'-source'" [placeholder]="choice.source" [(ngModel)]="source"
                        class="expand" #overrideSource></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </label>

    <label [attr.for]="choice.id" style="width: 50%" *ngIf="!isProduct">
      <div class="columns">
        <div class="mat-align">
          <div class="o-input-select-combo" style="width: 100%">
            <mat-checkbox style="position: relative" [id]="choice.id + '-showCus'" [checked]="showCus"
                          (change)="toggleShowCus()">
              {{'showCustomer' | cfgLabel}}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </label>

    <label [attr.for]="choice.id" style="width: 50%" *ngIf="choice.allowOptionalSelection">
      <div class="columns">
        <div class="mat-align">
          <span style="margin-left: 25%">{{'included' | cfgLabel}}</span>
          <div class="o-input-select-combo" style="width: 100%">
            <div class="switch tiny pad" style="margin: 0 10px">
              <input type="checkbox" [id]="'test'" [checked]="optional" class="switch-input u-no-margin"
                     (change)="updateIncluded(optionalCheckbox.checked)" #optionalCheckbox>
              <label [for]="'test'" class="switch-paddle"><span class="show-for-sr">Test</span></label>
            </div>
          </div>
          <span style="margin-right: 25%">{{'optional' | cfgLabel}}</span>
        </div>
      </div>
    </label>
  </div>
  <div class="row">
    <span style="margin: auto">
      <button mat-raised-button class="mat-secondary button u-no-margin radius" test-id="UPDATE_BUTTON" (click)="update()" mat-dialog-close>
        {{'update' | cfgLabel}}
      </button>
      <button mat-button class="mat-primary u-no-margin radius" test-id="CANCEL_BUTTON" (click)="deleteNewCustomField()" mat-dialog-close>
        {{'cancel' | cfgLabel}}
      </button>
    </span>
  </div>
</mat-dialog-content>
