<mat-dialog-content class="accent-color modal" id="item-multiplier">
  <div *ngIf="discountObj; else noDiscounts" style="max-width: 100%"
       [ngStyle]="{'width': is3Col(discountObj) ? '75rem' : '25rem'}">
    <button id="optionOverrideCloseButton" tabindex="-1" class="close-button" style="right: .5rem; top: 0rem" mat-dialog-close>×</button>

    <div class="row">
      <!--COLUMN 1-->
      <table class="small-12 medium-4" style="text-align: center" *ngIf="is3Col(discountObj)">
        <ng-container *ngIf="filteredColumn(discountObj, 1) as columnList">
          <th colSpan="{{displayMargin ? '3' : '2'}}">
            {{'Intercompany' | cfgLabel}}
          </th>
          <ng-container *ngTemplateOutlet="discountTableTemplate; context: {columnList: columnList}"></ng-container>
        </ng-container>
      </table>
      <!--COLUMN 2-->
      <table class="small-12" style="text-align: center" [ngClass]="{'medium-4': is3Col(discountObj)}">
        <ng-container
          *ngIf="is3Col(discountObj) ? filteredColumn(discountObj, 2) : filteredColumn(discountObj)  as columnList">
          <th *ngIf="is3Col(discountObj)" colSpan="{{displayMargin ? '3' : '2'}}">
            {{'EquipmentAndAccessories' | cfgLabel}}
          </th>
          <ng-container *ngTemplateOutlet="discountTableTemplate; context: {columnList: columnList}"></ng-container>
        </ng-container>
      </table>
      <!--COLUMN 3-->
      <table class="small-12 medium-4" style="text-align: center" *ngIf="is3Col(discountObj)">
        <ng-container *ngIf="filteredColumn(discountObj, 3)  as columnList">
          <th colSpan="{{displayMargin ? '3' : '2'}}" style="font-size: .8rem; line-height: 1.5rem">
            {{'orderRelatedEnggAndProjectMgmt' | cfgLabel}}
          </th>
          <ng-container *ngTemplateOutlet="discountTableTemplate; context: {columnList: columnList}"></ng-container>
        </ng-container>
      </table>
    </div>
    <div class="row" style="position: relative">
      <div style="margin: auto">
        <button mat-raised-button class="mat-secondary button u-no-margin radius" style="margin-right: 10px" test-id="APPLY_BUTTON"
                (click)="apply()">{{'apply' | cfgLabel}}</button>
        <button mat-button class="mat-primary u-no-margin radius" test-id="CLOSE_BUTTON" (click)="resetDiscounts()"
                mat-dialog-close>{{'closeButtonText' | cfgLabel}}</button>
      </div>
      <div *ngIf="(is3Col(discountObj) && !isMobile())" style="position: absolute; right: 0; bottom: 0;">
        <loading-image [display]="isLoading"></loading-image>
        {{'price' | cfgLabel}}: {{quickConfigData.price}}
      </div>
    </div>
    <div *ngIf="!is3Col(discountObj) || isMobile()" style="margin: auto 0; float: right">
      <loading-image [display]="isLoading"></loading-image>
      {{'price' | cfgLabel}}: {{quickConfigData.price}}
    </div>
  </div>
  <ng-template #noDiscounts>{{'noDiscountsAvaliable' | cfgLabel}}</ng-template>
  <ng-template #discountTableTemplate let-columnList="columnList">
    <tr style="font-weight: 600">
      <td>
        {{'discountClass' | cfgLabel}}
      </td>
      <td>
        {{'override' | cfgLabel}}
      </td>
      <td *ngIf="displayMargin">
        {{'distributorMargin' | cfgLabel}}
      </td>
    </tr>
    <ng-container *ngFor="let groupId of groupIdList(columnList); trackBy: groupId">
      <tr *ngIf="groupId !== ''">
        <td colSpan="{{displayMargin ? '3' : '2'}}">
          {{groupId}}
        </td>
      </tr>
      <tr *ngFor="let discount of discountSubList(columnList, groupId); trackBy: discount">
        <ng-container *ngTemplateOutlet="discountRowTemplate; context: {discount: discount}"></ng-container>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template #discountRowTemplate let-discount="discount">
    <td>
      {{discount.description}}
    </td>
    <td>
      <div class="row" style="padding: 0; margin: 0; justify-content: center; min-width: 100px">
        <mat-checkbox [ngStyle]="{'display': isEditable(discount) ? '' : 'none', 'padding-right': '.25rem'}"
                      [checked]="discount.overrideType === 'Item'" test-id="EDIT_OVERRIDE_FIELD"
                      (change)="updateOverrideType(discount, editOverride.checked, overrideInput.value)" #editOverride></mat-checkbox>
        <mat-form-field style="width: 4rem" class="slim-input" test-id="OVERRIDE_FIELD"
                        [ngStyle]="{'display': (isEditable(discount) && editOverride.checked) ? '' : 'none'}">
          <input matInput [value]="determineDiscountValue(discount).toFixed(2)"
                 style="padding: 0" (blur)="updateDiscount(discount, overrideInput.value)" #overrideInput>
        </mat-form-field>
        <ng-container *ngIf="!editOverride || !editOverride.checked">
          {{discountDefaultDisplay(discount).toFixed(2)}}
        </ng-container>
      </div>
    </td>
    <td *ngIf="displayMargin">
      <mat-form-field style="width: 4rem; margin: auto;" class="slim-input" test-id="MARGIN_FIELD"
                      *ngIf="editResellerMarginPrivilege; else disabledResellerMargin">
        <input matInput [value]="discount.resellerMargin > 0 ? discount.resellerMargin.toFixed(2) : ''" style="padding: 0"
               (change)="updateMargin(discount, marginInput.value)" #marginInput>
        <span matSuffix>%</span>
      </mat-form-field>
      <ng-template #disabledResellerMargin>
        {{discount.resellerMargin.toFixed(2)}}%
      </ng-template>
    </td>
  </ng-template>
</mat-dialog-content>
