<div class="row align-justify align-middle">
  <div class="columns">
    <h3 class="u-no-margin" [id]="category.id">{{category.description}}</h3>
  </div>
  <div *ngIf="firstCategory()" class="shrink columns" style="z-index: 10">
    <ng-template #space><br/></ng-template>
  </div>
</div>
<div class="row">
  <div class="small-12 columns">
    <div [ngClass]="{'c-card': !parentSubcatCard, 'row': skinnySubcat}">
      <ng-container *ngIf="!skinnySubcat; else split">
        <ul [ngClass]="{'accordion c-accordion u-no-background': !parentSubcatCard}">
          <cfg-parent-subcategory
            *ngFor="let parentSubcategory of category.parentSubcategories; trackBy: trackByParentSubcat" [userData]="userData"
            [parentSubcategory]="parentSubcategory" [parentSubcatCard]="parentSubcatCard"
            [quickConfigData]="quickConfigData" [skinnySubcat]="skinnySubcat"></cfg-parent-subcategory>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #split>
  <div class="two-cols">
    <ul [ngClass]="{'accordion c-accordion u-no-background': !parentSubcatCard}">
      <cfg-parent-subcategory
        *ngFor="let parentSubcategory of evenParentSubcats(); trackBy: trackByParentSubcat" [userData]="userData"
        [parentSubcategory]="parentSubcategory" [parentSubcatCard]="parentSubcatCard"
        [quickConfigData]="quickConfigData" [skinnySubcat]="skinnySubcat"></cfg-parent-subcategory>
    </ul>
    <ul [ngClass]="{'accordion c-accordion u-no-background': !parentSubcatCard}">
      <cfg-parent-subcategory
        *ngFor="let parentSubcategory of oddParentSubcats(); trackBy: trackByParentSubcat" [userData]="userData"
        [parentSubcategory]="parentSubcategory" [parentSubcatCard]="parentSubcatCard"
        [quickConfigData]="quickConfigData" [skinnySubcat]="skinnySubcat"></cfg-parent-subcategory>
    </ul>
  </div>
</ng-template>
