const IFE_SUBCATEGORY_PATH = '/config/api/v1/quickconfig/subcategory/';
const IFE_OPTION_RELATIVE_PATH = '/option';
const IFE_SESSION_COOKIE_INIT_PATH = '/config/api/v1/cookie/session';

export class UrlUtil {

  static getCurrentUrl() {
    return window.location.href;
  }

  private static parseUrlSafely(urlVal: string): URL {
    try {
      return new URL(urlVal);
    } catch {
      return new URL('http://localhost');
    }
  }

  static getPort() {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.port;
  }

  static getOrgFolderHome(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.searchParams.get('orgFolderHome');
  }

  static getOrgUnitFolderHome(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.searchParams.get('orgUnitFolderHome');
  }

  static getConfigBaseDir(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.searchParams.get('configBaseDir');
  }

  static getJSessionId(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getConfigInitUrl());
    return urlObj.searchParams.get('JSESSIONID');
  }

  static getConfigInitUrl(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.searchParams.get('configInitUrl');
  }

  static getOptionSubmitUrl(subcategoryId: string): string {
    return UrlUtil.getIFEDomain() + IFE_SUBCATEGORY_PATH + subcategoryId + IFE_OPTION_RELATIVE_PATH;
  }

  static getSessionCookieInitUrl(): string {
    return UrlUtil.getIFEDomain() + IFE_SESSION_COOKIE_INIT_PATH + '?jsessionId='
      + UrlUtil.getJSessionId() + '&path=/config';
  }

  static getIFEDomain(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getConfigInitUrl());
    return urlObj.protocol + '//' + urlObj.host;
  }

  static getCssPath(): string {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.searchParams.get('cssUrl');
  }

  static getInitAccordionCollapsed(): boolean {
    const urlObj = UrlUtil.parseUrlSafely(UrlUtil.getCurrentUrl());
    return urlObj.searchParams.get('initAccordionCollapsed') === 'true';
  }
}
