import {PostMessage} from './post-message';
import {QuickConfigData} from '../domain/QuickConfigData';

export class SubscribeActionProcessor {

  constructor(private postMessageUtil: PostMessage) {
  }

  processOptionChange(res: any, quickConfigData: QuickConfigData) {
    quickConfigData.updateCategories(res.categories, res.extendedSellPrice);
    if (res.currentProduct) {
      quickConfigData.updateProduct(res.currentProduct);
    }
    if (res.discounts) {
      quickConfigData.updateDiscounts(res.discounts);
    }
    this.postMessageUtil.publishPriceMessage(res.extendedSellPrice);
    this.postMessageUtil.publishLeadTimeMessage(res.leadTimeTotal);
    this.postMessageUtil.publishSelectorCallbackMessage(res.selectorCallbackDetails);
    this.postMessageUtil.publishQuantityItemsMessage(res.quantityItems);
    this.postMessageUtil.publishClearDocumentsCacheMessage();
  }
}
