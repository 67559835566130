import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {QuickConfigData} from '../domain/QuickConfigData';
import {ParentSubcategory} from '../domain/ParentSubcategory';
import {ParentSubcategoryAccordionService} from '../parent-subcategory-accordion/parent-subcategory-accordion.service';
import {trackByField, trackById} from '../util/track-by-util';
import {UserData} from '../domain/UserData';

@Component({
  selector: 'cfg-parent-subcategory',
  templateUrl: './parent-subcategory.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
      `
      cfg-parent-subcategory + cfg-parent-subcategory .c-accordion__item {
        margin-top: 0.5rem;
      }

      .scroll-adjust {
        margin-top: -70px !important;
        padding-top: 70px !important;
      }
    `
  ]
})
export class ParentSubcategoryComponent implements OnInit {

  @Input() parentSubcategory: ParentSubcategory;
  @Input() userData: UserData;
  @Input() quickConfigData: QuickConfigData;
  @Input() parentSubcatCard: boolean;
  @Input() skinnySubcat: boolean;

  constructor(private accordionService: ParentSubcategoryAccordionService) {
  }

  ngOnInit() {
    this.accordionService.initAccordionItem(this.parentSubcategory.id);
  }

  toggleAccordion(parentSubcategoryId) {
    this.accordionService.toggleAccordion(parentSubcategoryId);
  }

  isExpanded(parentSubcategoryId) {
    return this.accordionService.isExpanded(parentSubcategoryId);
  }

  hasInnerSubcats() {
    return this.parentSubcategory.subcategories && this.parentSubcategory.subcategories.length > 0;
  }

  getGraphicUrl(url) {
    return this.quickConfigData.imageTemplateUrl + url;
  }

  trackByField(index, field) {
    return trackByField(index, field);
  }

  trackBySubcat(index, subcat) {
    return trackById(index, subcat);
  }

  isMobile() {
    return window.innerWidth <= 640;
  }
}
