import {GrundfosProperties} from './grundfos.properties';
import {AcmeProperties} from './acme.properties';
import {UrlUtil} from '../util/url-util';
import {SulzerProperties} from './sulzer.properties';
import {PentairProperties} from 'app/properties/pentair.properties';
import {isNullOrUndefined} from '../util/js-util';
import {NovProperties} from './nov.properties';

const orgProperties = {
  grundfos: GrundfosProperties,
  pentair: PentairProperties,
};

export function propertiesSupport(feature: string, defaultVal?: boolean) {
  const org = UrlUtil.getOrgFolderHome();
  const configBaseDir = UrlUtil.getConfigBaseDir();
  if (isNullOrUndefined(safeGet(org, feature, configBaseDir))) {
    return nullCheck(safeGet(org, feature), defaultVal);
  }
  return nullCheck(safeGet(org, feature, configBaseDir), defaultVal);
}

function safeGet(org: string, feature: string, configBaseDir?) {
  try {
    if (configBaseDir && orgProperties[org][configBaseDir]) {
      return orgProperties[org][configBaseDir][feature];
    }
    return orgProperties[org][feature];
  } catch (e) {
    return null;
  }
}

export function setProperty(feature, value) {
  const org = UrlUtil.getOrgFolderHome();
  if (isNullOrUndefined(orgProperties[org])) {
    orgProperties[org] = {};
  }
  orgProperties[org][feature] = value;
}

function nullCheck(checkedVal, defaultVal) {
  return isNullOrUndefined(checkedVal) ? defaultVal : checkedVal;
}
