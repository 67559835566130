import {Component, EventEmitter, Input, Output} from '@angular/core';
import {QuickConfigService} from '../api/quick-config.service';
import {QuickConfigData} from '../domain/QuickConfigData';
import {PostMessage} from '../util/post-message';
import {Field} from '../domain/Field';
import {SubscribeActionProcessor} from '../util/subscribe-action-processor';
import {HttpParams} from '@angular/common/http';
import {ValidateFieldService} from '../api/validate-field.service';

@Component({
  selector: 'cfg-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {

  @Input() field: Field;
  @Input() parentSubcatId: string;
  @Input() quickConfigData: QuickConfigData;
  @Output()
  // This is used to emit an event to the parent i.e field.component.ts
  private change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private quickConfigService: QuickConfigService,
              private postMessageUtil: PostMessage,
              private validateFieldService: ValidateFieldService) {
  }

  stepSpinner(shouldAdd: boolean) {
    const currentVal = this.field.numericValue;
    const newVal = this.getNewVal(shouldAdd, currentVal);

    this.field.numericValue = newVal;
    this.field.value = this.getRoundedAndFormattedVal(newVal);
    if (this.validateFieldService.validateParameters([[this.field.httpNumericValueName, this.field.value.toString()]])) {
      this.submitSpinnerUpdate();
    }
  }

  private getNewVal(shouldAdd: boolean, currentVal: number) {
    const spinner = this.field.spinner;
    if (shouldAdd) {
      return currentVal + spinner.increment;
    } else {
      return currentVal - spinner.increment;
    }
  }

  submitSpinnerUpdate() {
    this.change.emit(true);
    let params = new HttpParams();
    const numericValueWithUserDecimalSymbol = this.field.numericValue.toString().replace('.', this.field.spinner.decimalSymbol);
    params = params.set(this.field.httpNumericValueName, numericValueWithUserDecimalSymbol);
    this.quickConfigService.submitFieldChange(this.parentSubcatId, params)
      .subscribe(res => {
        new SubscribeActionProcessor(this.postMessageUtil).processOptionChange(res, this.quickConfigData);
        this.change.emit(false);
      });
  }

  private getRoundedAndFormattedVal(newVal: number): string {
    const spinner = this.field.spinner;
    let maxFractionDigits = spinner.defaultMaxFractionDigits;
    let minFractionDigits = spinner.defaultMinFractionDigits;
    if (spinner.shouldUseDecimalLimits === true) {
      if (newVal < spinner.decimalLimitBaseValue) {
        maxFractionDigits = spinner.precisionBelowBaseValue;
        minFractionDigits = spinner.precisionBelowBaseValue;
      } else {
        maxFractionDigits = spinner.precisionAboveBaseValue;
        minFractionDigits = spinner.precisionAboveBaseValue;
      }
    }

    let newFormattedVal = Number(newVal).toLocaleString('en-US', {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits
    });
    newFormattedVal = this.applyRightGroupingAndDecimalSymbols(newFormattedVal, spinner);
    return newFormattedVal;
  }

  private applyRightGroupingAndDecimalSymbols(val: string, spinner: any) {
    return val.replace(/[.,]/g, function (m) {
      switch (m) {
        case '.':
          return spinner.decimalSymbol;
        case ',':
          return spinner.digitGroupingSymbol;
      }
    });
  }
}
