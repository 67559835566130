import {Injectable} from '@angular/core';
import * as Rollbar from 'rollbar';
import {environment} from '../../environments/environment';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: '234e9550ade54aacba05ec28d492fd43',
  captureUncaught: true,
  captureUnhandledRejections: true,
  verbose: true,

  payload: {
    client: {
      javascript: {
        code_version: environment.rollbar.code_version,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      }
    }
  },
};

@Injectable()
export class QCRollbarService extends Rollbar {

  constructor() {
    super(rollbarConfig);
    super.global({maxItems: 50});
    if (environment.rollbar.enabled) {
      super.configure({
        payload: {
          environment: environment.rollbar.code_version
        },
        autoInstrument: {
          network: true,
          log: true,
          dom: true,
          navigation: true,
          connectivity: true
        }
      });
    } else {
      super.configure({enabled: false});
    }
  }

  error(message: String, error?: Error, data = {}, callback?: Function): any {
    console.error(message, error, data);
    return super.error(
      message,
      error,
      {
        data: {
          ...data,
          message
        }
      },
      callback
    );
  }

}
