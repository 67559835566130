import {Component, Input} from '@angular/core';

@Component({
  selector: 'loading-image',
  templateUrl: './loading.component.html'
})
export class LoadingComponent {

  @Input()
  display: boolean;
  @Input()
  diameter = 18;
  @Input()
  color = 'primary';
  @Input()
  strokeWidth = 2;

}
