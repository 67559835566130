import {Component, Input} from '@angular/core';
import {Category} from '../domain/Category';
import {QuickConfigData} from '../domain/QuickConfigData';
import {ParentSubcategoryAccordionService} from '../parent-subcategory-accordion/parent-subcategory-accordion.service';
import {propertiesSupport} from '../properties/properties';
import {trackById} from '../util/track-by-util';
import {UserData} from '../domain/UserData';

@Component({
  selector: 'cfg-category',
  templateUrl: './category.component.html',
  styles: [
      `
      @media (min-width: 40em) {
        .two-cols {
          grid-template-columns: 1fr 1fr;
        }
      }

      @media (max-width: 39em) {
        .two-cols {
          grid-template-columns: 100%;
        }
      }

      .two-cols {
        display: grid;
        margin-right: 1.25em;
        width: 100%;
      }
    `
  ]
})
export class CategoryComponent {
  @Input() category: Category;
  @Input() quickConfigData: QuickConfigData;
  @Input() userData: UserData;
  @Input() parentSubcatCard: boolean;
  skinnySubcat: boolean;
  accordionMode: boolean;

  constructor(private accordionService: ParentSubcategoryAccordionService) {
    this.parentSubcatCard = propertiesSupport('subcategory_card', false);
    this.skinnySubcat = propertiesSupport('skinnySubcat', false);
    this.accordionMode = propertiesSupport('accordionMode', false) || propertiesSupport('initAccordionCollapsed', false);
  }

  expandAll() {
    this.accordionService.expandAll(this.quickConfigData.allParentSubcatIds());
  }

  collapseAll() {
    this.accordionService.collapseAll(this.quickConfigData.allParentSubcatIds());
  }

  firstCategory(): boolean {
    return this.quickConfigData.categories[0] === this.category;
  }

  expandAllButtonId() {
    return `expandAll`;
  }

  collapseAllButtonId() {
    return `collapseAll`;
  }

  private parentSubcategoryIds() {
    return this.category.parentSubcategories.map(p => p.id);
  }

  private evenParentSubcats() {
    let count = 0;
    return this.category.parentSubcategories.filter(parentSubcat => count++ % 2 === 0);
  }

  private oddParentSubcats() {
    let count = 0;
    return this.category.parentSubcategories.filter(parentSubcat => count++ % 2 === 1);
  }

  trackByParentSubcat(index, parentSubcat) {
    return trackById(index, parentSubcat);
  }
}
