import {HttpParameterCodec, HttpParams} from '@angular/common/http';

/**
 * Use with Angular {@link HttpParams} to encode special characters in Form POST requests
 * when the server expects `Content-Type` of `application/x-www-form-urlencoded`.
 *
 * @example
 * ```typescript
 * let params = new HttpParams({
 *     encoder: new HttpFormUrlEncodingCodec()
 * });
 * ```
 *
 * @see {@link https://medium.com/better-programming/how-to-fix-angular-httpclient-not-escaping-url-parameters-ddce3f9b8746}
 */
export class HttpFormUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
