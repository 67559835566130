<span [ngStyle]="alertStyles()">
  <div *ngIf="displayTitle()">
    {{content.value && content.description}}
    <sup *ngIf="isOptionGraphicDisplay_Comment(content)||content.optionComments"
         [ngbPopover]="tooltip" placement="right bottom left">
      <span class="fas fa-info-circle fa-fw"></span>
    </sup>
  </div>
  <span [innerHtml]="content.value || content.description"></span>
  <sup *ngIf="!displayTitle() && (isOptionGraphicDisplay_Comment(content)||content.optionComments)"
       [ngbPopover]="tooltip" placement="right bottom left">
    <span class="fa fa-info-circle"></span>
  </sup>
</span>
<ng-template #tooltip>
  <div>
    <img *ngIf="isOptionGraphicDisplay_Comment(content)"
         [src]="getGraphicUrl(content.optionGraphic)"
         [ngStyle]="{'height': graphicSize[content.graphicDisplaySize]}">
    <span [innerHtml]="content.optionComments"></span>
  </div>
</ng-template>
