import {Component, Input} from '@angular/core';
import {QuickConfigData} from '../domain/QuickConfigData';
import {UserData} from '../domain/UserData';
import {Subcategory} from '../domain/Subcategory';
import {trackByField} from '../util/track-by-util';

@Component({
  selector: 'cfg-inner-subcategory',
  templateUrl: './inner-subcategory.component.html',
})
export class InnerSubcategoryComponent {

  @Input() subcategory: Subcategory;
  @Input() quickConfigData: QuickConfigData;
  @Input() userData: UserData;
  @Input() skinnySubcat: boolean;

  trackByField(index, field) {
    return trackByField(index, field);
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isMedium() {
    return window.innerWidth <= 889;
  }
}
