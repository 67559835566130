import {Component, Input, OnInit} from '@angular/core';
import {QuickConfigService} from '../api/quick-config.service';
import {QuickConfigData} from '../domain/QuickConfigData';
import {Field} from '../domain/Field';
import {PostMessage} from '../util/post-message';
import {Choice} from '../domain/Choice';
import {SubscribeActionProcessor} from '../util/subscribe-action-processor';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {OptionOverrideComponent} from '../option-override/option-override.component';
import {UserData} from '../domain/UserData';
import {ValidateFieldService} from '../api/validate-field.service';
import {isNullOrUndefined} from '../util/js-util';
import {NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/index';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {Category} from '../domain/Category';
import {GRAPHIC_SIZE, isOptionGraphicDisplay_Comment, isOptionGraphicDisplay_Inline} from '../util/field-util';

@Component({
  selector: 'cfg-field',
  templateUrl: './field.component.html',
  styles: [
      `:host ::ng-deep .mat-form-field-infix {
      display: flex
    }

    .input-group-label {
      border-bottom: 0;
      border-top: 0;
    }

    .option-description {
      white-space: pre-line;
    }

    .graphic-top {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .graphic-left {
      display: flex;
      align-items: center;
    }

    .graphic-top > img {
      margin-top: .25rem;
    }
    `
  ]
})
export class FieldComponent implements OnInit {
  @Input() field: Field;
  @Input() parentSubcatId: string;
  @Input() quickConfigData: QuickConfigData;
  @Input() userData: UserData;
  private overridePrice: boolean;
  private overrideDescription: boolean;
  private overrideRFQ: boolean;
  private viewListPrice: boolean;
  public viewOptionOverrides: boolean;
  private _selectValue: string;

  graphicSize = GRAPHIC_SIZE;
  numRows = {
    'X-SMALL': 1,
    'SMALL': 3,
    'MEDIUM': 5,
    'LARGE': 7,
  };

  constructor(private quickConfigService: QuickConfigService,
              private postMessageUtil: PostMessage,
              private dialog: MatDialog,
              private validateFieldService: ValidateFieldService,
              config: NgbPopoverConfig) {
    config.placement = 'right';
    config.triggers = 'hover';
  }

  ngOnInit(): void {
    this.overrideDescription = this.userData.privileges['overrideDescription'];
    this.overridePrice = this.userData.privileges['overridePrice'];
    this.overrideRFQ = this.userData.privileges['overrideRFQ'];
    this.viewListPrice = this.userData.privileges['viewListPrice'];
    this.viewOptionOverrides = this.userData.privileges['viewOptionOverrides'];
    if (!!this.field.updates) {
      let params = new HttpParams();
      this.field.updates.forEach(paramPair => params = params.set(paramPair[0], paramPair[1]));
      this.submitChange(params);
    }
    this.updateSelectValue();
  }

  submitChange(params: HttpParams) {
    this.field.displayLoading = true;
    this.quickConfigService.submitFieldChange(this.parentSubcatId, params)
      .subscribe(res => {
        new SubscribeActionProcessor(this.postMessageUtil).processOptionChange(res, this.quickConfigData);
        this.field.displayLoading = false;
      });
  }

  updateSelectValue() {
    if (this.field.displayType === 'DROPDOWN') {
      this._selectValue = this.selectedChoice() && this.selectedChoice().httpValue;
    } else {
      this._selectValue = this.field.value;
    }
  }

  get selectValue(): string {
    return this._selectValue;
  }

  set selectValue(value: string) {
    this._selectValue = value;
  }

  public selectedChoice() {
    const choice = this.field.choices
      .find(c => c.selected);

    if (this.field.choices.length === 1) {
      return this.field.choices[0];
    }
    return choice || null;
  }

  submitDropdownFieldChange(value) {
    this.field.choices.forEach(choice => {
      if (choice.httpValue === value) {
        choice.selected = true;
      } else {
        choice.selected = false;
      }
    });
    this.validateAndSubmitChange([[this.field.httpName, value]]);
  }

  isTrue(): Observable<boolean> {
    return;
  }

  submitFieldChange(value) {
    this.validateAndSubmitChange([[this.field.httpName, value]]);
  }

  submitNumericFieldChange(value) {
    this.validateAndSubmitChange([[this.field.httpNumericValueName, value]]);
  }

  validateAndSubmitChange(changeParams: any[][]) {
    if (this.validateFieldService.validateParameters(changeParams)) {
      let params = new HttpParams();
      changeParams.forEach(paramPair => params = params.set(paramPair[0], paramPair[1]));
      this.submitChange(params);
    }
  }

  submitMultipickFieldChange(choice: Choice) {
    const changeParams = [];
    changeParams.push([choice.httpName, String(choice.selected)]);
    changeParams.push([choice.httpDirtyName, 'true']);
    changeParams.push(['quantity_' + (choice.httpValue || choice.id), '' + choice.quantity]);
    this.validateAndSubmitChange(changeParams);
    choice.displayLoading = true;
  }

  submitNumericChoiceChange(choice, value) {
    const changeParams = [];
    changeParams.push([choice.httpNumericValueName, value]);
    changeParams.push([choice.httpDirtyName, 'true']);
    this.validateAndSubmitChange(changeParams);
    choice.displayLoading = true;
  }

  submitUOMChange(uom, option?) {
    if (!option) {
      option = this.field;
    }
    this.validateAndSubmitChange([[option.unitOfMeasureField.httpName, uom]]);
  }

  hasUnitOfMeasure(option?) {
    if (!option) {
      option = this.field;
    }
    return option.unitOfMeasureField != null && option.unitOfMeasureField.choices.length > 0;
  }

  unitOfMeasureSymbol(option?) {
    return this.selectedUnitOfMeasure(option).description;
  }

  private selectedUnitOfMeasure(option?) {
    if (!option) {
      option = this.field;
    }
    return option.unitOfMeasureField.choices
      .find(choice => choice.selected);
  }

  isDisabled() {
    return !this.field.editable || (this.field.choices && this.field.displayType !== 'MULTIPICK' && this.field.choices.length === 1);
  }

  spinnerDisplayLoadingChange(event) {
    this.field.displayLoading = event;
  }

  fieldDescription() {
    return this.field.description || '\u00A0';
  }

  displayTitle(): boolean {
    return !(this.field.menuType && this.field.menuType.includes('notitle'));
  }

  getGraphicUrl(url) {
    return this.quickConfigData.imageOptionUrl + url;
  }

  fieldDropdownOrAutocomplete() {
    if (this.field.displayType === 'DROPDOWN') {
      return 'DROPDOWN';
    }
    if (this.field.displayType === 'AUTOCOMPLETE') {
      return 'AUTOCOMPLETE';
    }
  }

  trackByChoice(index, choice) {
    return choice ? choice.id + ':::' + choice.selected : undefined;
  }

  openOverrideDialog(choice, object?) {
    let offset = 0;
    if (object) {
      offset = object.clientY;
      offset = offset - (object.screenY - 50) / 2;
      if (offset < 50) {
        offset = 50;
      }
    }
    choice.displayLoading = false;
    const dialogRef = this.dialog.open(OptionOverrideComponent, {
      panelClass: 'modal',
      position: {top: offset + 'px'},
      data: {
        choice: choice, parentSubcatId: this.parentSubcatId,
        overridePrice: this.overridePrice || this.overrideRFQ && choice.rfq,
        overrideDescription: this.overrideDescription && choice.overrideDesc,
        quickConfigService: this.quickConfigService, viewListPrice: this.viewListPrice,
        postMessageUtil: this.postMessageUtil, quickConfigData: this.quickConfigData
      }
    });
  }

  isInvalid(): boolean {
    if (!(this.field.invalid === undefined)) {
      return this.field.invalid;
    }
    return false;
  }

  deleteCustomField(field, object?) {
    let offset = 0;
    if (object) {
      offset = object.clientY;
      offset = offset - (object.screenY - 50) / 2;
      if (offset < 50) {
        offset = 50;
      }
    }
    const popup = ConfirmDialogComponent;
    const dialogRef = this.dialog.open(popup, {
      position: {top: offset + 'px'},
      data: {confirmationPrompt: 'doYouWantToDelete'}
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.quickConfigService.deleteCustomOption(field)
          .subscribe((res: { categories: Category[], extendedSellPrice: string, leadTimeTotal: string }) => {
            this.quickConfigData.updateCategories(res.categories, res.extendedSellPrice);
            this.postMessageUtil.publishPriceMessage(res.extendedSellPrice);
            this.postMessageUtil.publishLeadTimeMessage(res.leadTimeTotal);
          });
      }
    });
  }

  isOptionGraphicDisplay_Comment(container: (Field | Choice)) {
    return isOptionGraphicDisplay_Comment(container);
  }

  isOptionGraphicDisplay_Inline(container: (Field | Choice)) {
    return isOptionGraphicDisplay_Inline(container);
  }

  displayQuantity(option: Field | Choice) {
    if (!isNullOrUndefined((<Choice>option).selected) && !(<Choice>option).selected) {
      return false;
    }
    return option.price && (option.quantity > 1 || option.quantityEditable);
  }
}

