import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {QCRollbarService} from './qc-rollbar.service';

@Injectable()
export class QCRollbarErrorHandler implements ErrorHandler {

  constructor(private qcRollbar: QCRollbarService) {
  }

  handleError(err: any): void {
    this.qcRollbar.error(err.originalError || err, err);
  }
}

@NgModule({
  imports: [BrowserModule],
  declarations: [],
  bootstrap: [],
  providers: [
    {provide: ErrorHandler, useClass: QCRollbarErrorHandler},
    QCRollbarService,
  ]
})
export class RollbarModule {
}
