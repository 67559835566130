<li class="accordion-item c-accordion__item">
  <h5 *ngIf="subcategory.description" [id]="subcategory.id" class="c-performance-heading">
    {{subcategory.description}}
  </h5>
  <div [ngClass]="{'accordion-content c-accordion__content': !subcategory.menuType.includes('notitle')}" style="display: block;"
       [ngStyle]="{'padding': !subcategory.menuType.includes('notitle') ? '.5rem .5rem 0': ''}" data-tab-content>
    <div class="grid-container"
         [ngClass]="{'multi-column': !(isMobile() || (isMedium() && subcategory.colSpan === 1)), 'single-column': (isMobile() || (isMedium() && subcategory.colSpan === 1))}">
      <div *ngFor="let field of subcategory.fields; trackBy: trackByField" class="grid-item field-item"
           [ngClass]="['row-span-' + field.rowSpan, 'column-span-' + field.colSpan, 'row-' + field.rowPos, 'column-' + field.colPos, 'mobile-' + field.mobilePos]">
        <cfg-field style="width:100%" [field]="field" [parentSubcatId]="subcategory.id" [userData]="userData"
                   [quickConfigData]="quickConfigData"></cfg-field>
      </div>
    </div>
  </div>
</li>
