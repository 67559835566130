<div [ngSwitch]="field.displayType">

  <div *ngSwitchCase="fieldDropdownOrAutocomplete()" style="padding-bottom: 10px">
    <label [attr.for]="field.id">
      <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
      <div class="row align-middle">
        <div class="columns" style="display: flex">
          <ng-container *ngTemplateOutlet="dropdownAndOverride;context:{selectedChoice: selectedChoice()}"></ng-container>
          <ng-template #dropdownAndOverride let-selectedChoice="selectedChoice">
            <mat-select [(ngModel)]="selectValue" [disabled]="isDisabled()" [name]="field.httpName" [id]="field.id"
                      (selectionChange)="submitDropdownFieldChange(dropdownFieldVar.value)"
                      [ngStyle]="{'color': !field.editable ? 'rgba(0,0,0,0.50)' : ''}"  #dropdownFieldVar>
            <mat-select-trigger style="display: block;"
              [ngClass]="{'graphic-top': selectedChoice?.graphicDisplayLocation === 'INLINE_TOP',
                                              'graphic-left': selectedChoice?.graphicDisplayLocation === 'INLINE_LEFT'}">
              <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:selectedChoice}"></ng-container><div class="selected-option-text">{{selectedChoice?.description}}<span
                style="float: right">{{selectedChoice && ((selectedChoice.price | cfgLabel) || selectedChoice.price)}}</span></div>
            </mat-select-trigger>
            <mat-option *ngFor="let choice of field.choices"
                        [ngClass]="{'graphic-top': choice.graphicDisplayLocation === 'INLINE_TOP',
                                              'graphic-left': choice.graphicDisplayLocation === 'INLINE_LEFT'}"
                        [ngStyle]="{'height': isOptionGraphicDisplay_Inline(choice) && graphicSize[choice.graphicDisplaySize],
                                        'margin-bottom': isOptionGraphicDisplay_Inline(choice)? '5px' : ''}"
                        [value]="choice.httpValue" [id]="choice.id" [selected]="choice.selected"><ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice}"></ng-container>
              <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice}"></ng-container>
              <div class="selected-option-text">
              {{choice.description}}<span style="float: right">{{ (choice.price | cfgLabel) || choice.price}}</span></div>
            </mat-option>
          </mat-select>
          <button *ngIf="viewOptionOverrides && selectedChoice" matTooltip="{{'editOptionDetails' | cfgLabel}}" test-id="OVERRIDE_BUTTON"
                  (click)="openOverrideDialog(selectedChoice, $event)" style="padding-left: 5px"><span class="fa fa-edit"></span>
          </button>
          </ng-template>
        </div>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'RADIO'" style="padding-bottom: 10px">
    <label [attr.for]="field.id">
      <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
      <div class="row align-middle">
        <div class="columns">
          <mat-radio-group [name]="field.id" style="flex-direction: column; display: inline-flex; padding-left: .5rem">
            <div *ngFor="let choice of field.choices" style="display: inline">
              <ng-container *ngIf="!choice.alert; else alert">
                <mat-radio-button style="max-width: 100%" [value]="choice.httpValue" [id]="choice.id" [checked]="choice.selected"
                                  [name]="field.id"
                                  [disabled]="isDisabled()" (change)="submitFieldChange(choice.httpValue)"
                                  [ngClass]="{'graphic-top': choice.graphicDisplayLocation === 'INLINE_TOP',
                                            'graphic-left': choice.graphicDisplayLocation === 'INLINE_LEFT'}">
                  <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice, marginTop:'5px'}"></ng-container>
                  <div style="display: table-cell; text-align: left; vertical-align: middle; word-break: break-word"
                       [ngStyle]="{'height': isOptionGraphicDisplay_Inline(choice) && graphicSize[choice.graphicDisplaySize],
                                   'margin-top': isOptionGraphicDisplay_Inline(choice)? '5px' : ''}">
                    <span class="option-description">{{choice.description}}</span>
                    <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
                    <loading-image
                      [display]="choice.displayLoading && !(field.description && displayTitle() && displayLoading)"></loading-image>
                  </div>
                </mat-radio-button>
                <div style="float: right; line-height: 24px">
                  <loading-image
                    [display]="choice.displayLoading && !(field.description && displayTitle() && displayLoading)"></loading-image>
                  <ng-container *ngTemplateOutlet="priceDisplay;context:{option: choice}"></ng-container>
                  <button *ngIf="viewOptionOverrides" test-id="OVERRIDE_BUTTON" (click)="openOverrideDialog(choice, $event)"
                          style="padding-left: 5px" matTooltip="{{'editOptionDetails' | cfgLabel}}"><span class="fa fa-edit"></span>
                  </button>
                </div>
              </ng-container>
              <ng-template #alert>
                <cfg-alert [content]="choice" [quickConfigData]="quickConfigData"></cfg-alert>
              </ng-template>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'MULTIPICK'">
    <label [attr.for]="field.id">
      <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
      <div class="row align-middle" style="padding-top:.25rem">
        <div *ngFor="let choice of field.choices; trackBy: trackByChoice" class="small-12 columns" style="display: inline;">
          <ng-container *ngIf="!choice.alert; else alert">
            <label [attr.for]="choice.id"
                   [ngStyle]="{'display: table; width: 100%; table-layout: fixed': true, 'font-style': choice.optional ? 'italic' : 'normal'}">
              <ng-container *ngIf="choice.numericValue != null; else checkbox">
                <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                  <span style="margin: auto 0" class="option-description">{{choice.description}}</span>
                  <span style="max-width: 16rem;display: flex; clear: right; padding: 5px 0; justify-content: flex-end">
                        <input [name]="choice.httpName" [id]="choice.id" [value]="choice.value"
                               [ngStyle]="{'max-width': hasUnitOfMeasure(choice) ? 'calc(100% - 6rem)' : '80%'}"
                               (change)="submitNumericChoiceChange(choice, numericChoiceVar.value)"
                               class="input-group-field" type="text" #numericChoiceVar>
                        <select style="width: 6rem;margin: 0" [disabled]="isDisabled()" *ngIf="hasUnitOfMeasure(choice)"
                                [name]="choice.unitOfMeasureField.httpName"
                                [id]="choice.unitOfMeasureField.id"
                                (change)="submitUOMChange(uomVar.value, choice)"
                                #uomVar>
                          <option *ngFor="let unit of choice.unitOfMeasureField.choices" [selected]="unit.selected"
                                  [value]="unit.httpValue" [id]="unit.id">
                            {{unit.description}}
                          </option>
                        </select>
                      </span>
                </div>
              </ng-container>
              <ng-template #checkbox>
                <mat-checkbox [id]="choice.id" [(ngModel)]="choice.selected"
                              [ngClass]="{'graphic-top': choice.graphicDisplayLocation === 'INLINE_TOP',
                              'graphic-left': choice.graphicDisplayLocation === 'INLINE_LEFT'}"
                              [disabled]="isDisabled() && !choice.editable" (change)="submitMultipickFieldChange(choice)">
                  <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice,marginBottom:'5px'}"></ng-container>
                  <div [ngStyle]="{'height': isOptionGraphicDisplay_Inline(choice) && graphicSize[choice.graphicDisplaySize],
                         'margin-top': isOptionGraphicDisplay_Inline(choice) ? '5px' : ''}">
                    <span style="display: flex">
                      <span class="option-description">{{choice.description}}</span>
                      <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
                    </span>
                  </div>
                </mat-checkbox>
              </ng-template>
              <div style="float: right; line-height: 24px">
                <loading-image
                  [display]="choice.displayLoading && !(field.description && displayTitle() && displayLoading)"></loading-image>
                <ng-container *ngTemplateOutlet="priceDisplay;context:{option: choice}"></ng-container>
                <button *ngIf="viewOptionOverrides" matTooltip="{{'editOptionDetails' | cfgLabel}}" test-id="OVERRIDE_BUTTON"
                        (click)="openOverrideDialog(choice, $event)" style="padding-left: 5px">
                  <span class="fa fa-edit"></span>
                </button>
              </div>
            </label>
          </ng-container>
          <ng-template #alert>
            <cfg-alert [content]="choice" [quickConfigData]="quickConfigData"></cfg-alert>
          </ng-template>
        </div>
      </div>
    </label>
  </div>


  <div *ngSwitchCase="'READONLY'">
    <label style="padding-bottom:.8rem">
      <ng-container *ngIf="!field.alert; else alert">
        <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
        <div class="row align-middle">
          <div class="columns" [id]="field.id"
               [ngClass]="{'graphic-top': field.graphicDisplayLocation === 'INLINE_TOP',
                            'graphic-left': field.graphicDisplayLocation === 'INLINE_LEFT'}">
            <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:field}"></ng-container>
            <i style="padding-left:.5rem" [innerHtml]="field.value"></i>
          </div>
        </div>
      </ng-container>
    </label>
  </div>

  <div *ngSwitchCase="'CUSTOM_OPTION'">
    <label style="padding-bottom:.8rem; padding-top: 1rem">
      <div>
        <div class="row align-middle">
          <div class="columns" [id]="field.id" [ngStyle]="{'font-style': field.optional ? 'italic' : 'normal'}"
               [ngClass]="{'graphic-top': field.graphicDisplayLocation === 'INLINE_TOP',
             'graphic-left': field.graphicDisplayLocation === 'INLINE_LEFT'}">
            <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:field}"></ng-container>
            {{field.description}}
            <div style="display: inline-flex; float: right">
              <loading-image [display]="field.displayLoading"></loading-image>
              <ng-container
                *ngTemplateOutlet="priceDisplay;context:{option: field}"></ng-container>
              <button (click)="openOverrideDialog(field, $event)" matTooltip="{{'editOptionDetails' | cfgLabel}}" style="padding-left: 5px"
                      test-id="OVERRIDE_BUTTON">
                <i class="fa fa-edit"></i>
              </button>
              <button test-id="DELETE_CUSTOM_FIELD_BUTTON" (click)="deleteCustomField(field, $event)">
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'NUMERIC_INPUT'">
    <label [attr.for]="field.id">
      <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
      <div class="row align-middle" *ngIf="!field.alert; else alert">
        <div class="columns">
          <div class="input-group">
            <mat-form-field>
              <input matInput [name]="field.httpName" [id]="field.id" [value]="field.value" autocomplete="off"
                     (change)="submitNumericFieldChange(numericFieldVar.value)" #numericFieldVar>
              <mat-hint align="start" *ngIf="isInvalid()" style="color:red">
                Value must be between {{field.minValue|number}} and {{field.maxValue|number}}
              </mat-hint>
              <span *ngIf="hasUnitOfMeasure()" class="input-group-label" style="margin-bottom: 1px">{{unitOfMeasureSymbol()}}</span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </label>
  </div>


  <div *ngSwitchCase="'NUMERIC_SPINNER'">
    <label [attr.for]="field.id">
      <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
      <div class="row align-middle" *ngIf="!field.alert; else alert">
        <div class="columns">
          <div class="row">
            <div class="columns">
              <div class="input-group">
                <mat-form-field>
                  <input matInput [name]="field.httpName" [id]="field.id" [value]="field.value"
                         (change)="submitNumericFieldChange(spinnerFieldVar.value)"
                         class="input-group-field" type="text" size="4" #spinnerFieldVar>
                  <mat-hint align="start" *ngIf="isInvalid()" style="color:red">
                    Value must be between {{field.minValue|number}} and {{field.maxValue|number}}
                  </mat-hint>
                  <span *ngIf="hasUnitOfMeasure()" class="input-group-label">{{unitOfMeasureSymbol()}}</span>
                </mat-form-field>
                <cfg-spinner [field]="field" [quickConfigData]="quickConfigData" style="margin-bottom: 1.25em"
                             [parentSubcatId]="parentSubcatId" (change)="spinnerDisplayLoadingChange($event)"></cfg-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>


  <div *ngSwitchCase="'TEXT_INPUT'">
    <label [attr.for]="field.id">
      <ng-template *ngTemplateOutlet="titleDisplay"></ng-template>
      <div class="row align-middle" *ngIf="!field.alert; else alert">
        <div class="columns">
          <ng-container *ngIf="field.textArea==='NO'; else textArea">
            <input type="text" [name]="field.httpName" [id]="field.id" [value]="field.value" autocomplete="off"
                   (change)="submitFieldChange(textInputFieldVar.value)" #textInputFieldVar>
          </ng-container>
          <ng-template #textArea>
            <mat-form-field>
              <textarea matInput rows="{{numRows[field.textArea]}}" [name]="field.httpName" [id]="field.id"
                        [value]="field.value" [disabled]="isDisabled()"
                        (change)="submitFieldChange(textInputFieldVar.value)" #textInputFieldVar>
              </textarea>
            </mat-form-field>
          </ng-template>
        </div>
      </div>
    </label>
  </div>
</div>

<ng-template #infoUrl let-option="option">
  <ng-container *ngIf="option.infoUrl">
    <sup><a [href]="option.infoUrl" target="_blank" matTooltip="Click to view external webpage" matTooltipPosition="above">
      <span class="fa fa-external-link fa-fw" data-fa-transform="up-2"></span></a></sup>
  </ng-container>
</ng-template>

<ng-template #popOverTrigger let-option="option">
  <ng-container *ngTemplateOutlet="infoUrl; context:{option: option}"></ng-container>
  <sup [ngbPopover]="tooltip" placement="right bottom top left">
    <span *ngIf="isOptionGraphicDisplay_Comment(option) || option.optionComments" class="fa fa-info-circle"></span>
  </sup>
  <ng-template #tooltip>
    <div>
      <img *ngIf="isOptionGraphicDisplay_Comment(option)" [src]="getGraphicUrl(option.optionGraphic)">
      <span [innerHtml]="option.optionComments"></span>
    </div>
  </ng-template>
</ng-template>

<ng-template #alert>
  <cfg-alert [content]="field" [quickConfigData]="quickConfigData"></cfg-alert>
</ng-template>

<ng-template #titleDisplay>
  <div *ngIf="displayTitle() && field.description" style="display: inline-block" class="cfa-field-title">
    {{field.description}}
    <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: field}"></ng-container>
    <loading-image [display]="field.displayLoading"></loading-image>
  </div>
</ng-template>

<ng-template #priceDisplay let-option="option">
  <ng-container *ngIf="displayQuantity(option)">{{option.quantity}}&times;</ng-container>
  <ng-container *ngIf="option.price">{{(option.price | cfgLabel) || option.price}}<span *ngIf="option.priceOverridden"
                                                                                        matTooltip="{{'priceOverriddenByUser' | cfgLabel}}">*</span>
  </ng-container>
</ng-template>

<ng-template #optionGraphicInline let-container="container">
  <img *ngIf="isOptionGraphicDisplay_Inline(container)"
       [ngStyle]="{'height': graphicSize[container.graphicDisplaySize]}"
       [src]="getGraphicUrl(container.optionGraphic)">
</ng-template>
