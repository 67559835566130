import {Pipe, PipeTransform} from '@angular/core';
import {LabelService} from './label.service';

@Pipe({name: 'cfgLabel'})
export class LabelPipe implements PipeTransform {
  constructor(private labelService: LabelService) {
  }

  transform(key: string): string {
    return this.labelService.get(key);
  }
}
