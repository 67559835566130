import {UrlUtil} from './url-util';
import {Inject, Injectable} from '@angular/core';
import {ParentWindowToken} from './injection-tokens';

@Injectable()
export class PostMessage {
  constructor(@Inject(ParentWindowToken) private parentWindow) {
    if (parentWindow === window) {
      this.parentWindow = window.parent.window;
    }
  }

  publishPriceMessage(price) {
    this.parentWindow.postMessage({'messageType': 'extendedSellPrice', 'value': price}, UrlUtil.getIFEDomain());
  }

  publishLeadTimeMessage(leadTimeTotal) {
    this.parentWindow.postMessage({'messageType': 'leadTimeTotal', 'value': leadTimeTotal}, UrlUtil.getIFEDomain());
  }

  publishSelectorCallbackMessage(selectorCallbackDetails) {
    if (selectorCallbackDetails.performedSelectorCallback) {
      this.parentWindow.postMessage({
          'messageType': 'selectorCallBackDetails', 'value':
            {
              'performedSelectorCallback': selectorCallbackDetails.performedSelectorCallback,
              'rejectStatus': selectorCallbackDetails.rejectStatus
            }
        },
        UrlUtil.getIFEDomain());
    }
  }

  publishQuantityItemsMessage(quantityItems: number) {
    this.parentWindow.postMessage({'messageType': 'quantityItems', 'value': quantityItems},
      UrlUtil.getIFEDomain());
  }

  publishClearDocumentsCacheMessage() {
    this.parentWindow.postMessage({'messageType': 'clearDocumentsCache'},
      UrlUtil.getIFEDomain());
  }

  publishLoadedMessage() {
    this.parentWindow.postMessage({'messageType': 'quickConfigIFrameLoaded', 'value': 'true'},
      UrlUtil.getIFEDomain());
  }

  publishSubcatListData(subcatList) {
    this.parentWindow.postMessage({'messageType': 'customOptionMenuData', 'value': subcatList}, UrlUtil.getIFEDomain());
  }

  displaySpecialOptionButton(subcatList) {
    this.parentWindow.postMessage(
      {'messageType': 'customOptionExists', 'value': subcatList.filter(subcat => subcat.addedOptions).length > 0}
      , UrlUtil.getIFEDomain());
  }
}
