import {Category} from './Category';
import {Labels} from './Labels';
import {UserData} from './UserData';

export class QuickConfigData {

  static create(quickConfigInit): QuickConfigData {
    return new QuickConfigData(
      quickConfigInit.categories,
      quickConfigInit.labels,
      quickConfigInit.userData,
      quickConfigInit.discounts,
      quickConfigInit.currentProduct,
      quickConfigInit.extendedSellPrice,
      quickConfigInit.leadTimeTotal,
      quickConfigInit.imageOptionUrl,
      quickConfigInit.imageTemplateUrl,
      quickConfigInit.hasKnowledgeBaseChanged,
      quickConfigInit.itemId
    );
  }

  constructor(public categories: Category[],
              public labels: Labels,
              public userData: UserData,
              public discounts: any[],
              public product: any,
              public price = '',
              public leadTimeTotal = '',
              public imageOptionUrl = '',
              public imageTemplateUrl = '',
              public hasKnowledgeBaseChanged = false,
              public itemId = '') {
  }

  updateCategories(updatedCategories: Category[], extendedSellPrice: string) {
    this.categories = updatedCategories;
    this.price = extendedSellPrice;
  }
  updateProduct(product: any) {
    this.product = product;
  }

  updateDiscounts(discounts: any[]) {
    this.discounts = discounts;
  }

  findCategory(categoryId: string) {
    return this.categories.find(category => category.id === categoryId);
  }

  findParentSubcatInCategories(parentSubcatId: string) {
    return this.categories.map(category => category.parentSubcategories)
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .find(parentSubcat => parentSubcat.id === parentSubcatId);
  }

  findFieldInCategories(fieldId: string) {
    return this.categories.map(category => category.parentSubcategories)
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .map(parentSubcat => parentSubcat.fields)
      .reduce((result, field) => result.concat(field), []) // flatten the fields
      .find(field => field.id === fieldId);
  }

  allParentSubcatIds() {
    return this.categories.map(category => category.parentSubcategories.map(p => p.id))
      .reduce((ids, catIds) => ids.concat(catIds), []);
  }
}
