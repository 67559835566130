import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {QuickConfigService} from './api/quick-config.service';
import {CategoryComponent} from './category/category.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {ParentSubcategoryComponent} from './parent-subcategory/parent-subcategory.component';
import {FieldComponent} from './field/field.component';
import {PostMessage} from './util/post-message';
import {ParentWindowToken} from './util/injection-tokens';
import {LoadingComponent} from './loading/loading.component';
import {LocalStorageModule, LocalStorageService} from 'angular-2-local-storage';
import {ParentSubcategoryAccordionService} from './parent-subcategory-accordion/parent-subcategory-accordion.service';
import {GlobalCssComponent} from './global-css.component';
import {RollbarModule} from './rollbar/rollbar.module';
import {InnerSubcategoryComponent} from './inner-subcategory/inner-subcategory.component';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {QCMaterialModule} from './util/qc-material.module';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {OptionOverrideComponent} from './option-override/option-override.component';
import {AlertComponent} from './alert/alert.component';
import {ItemMultiplierComponent} from './item-multiplier/item-multiplier.component';
import {RecieveMessage} from './util/recieve-message';
import {ValidateFieldService} from './api/validate-field.service';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {LabelPipe} from './label/label.pipe';

@NgModule({
    declarations: [
        AppComponent,
        ParentSubcategoryComponent,
        CategoryComponent,
        FieldComponent,
        SpinnerComponent,
        LoadingComponent,
        GlobalCssComponent,
        InnerSubcategoryComponent,
        OptionOverrideComponent,
        AlertComponent,
        ItemMultiplierComponent,
        ConfirmDialogComponent,
        LabelPipe
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RollbarModule,
        LocalStorageModule.forRoot({
            prefix: 'quick-config',
            storageType: 'sessionStorage'
        }),
        RouterModule.forRoot([{ path: '**', component: CategoryComponent }], { relativeLinkResolution: 'legacy' }),
        Angulartics2Module.forRoot([Angulartics2GoogleAnalytics], {}),
        QCMaterialModule,
        NoopAnimationsModule,
        NgbPopoverModule,
        BrowserAnimationsModule
    ],
    providers: [
        QuickConfigService,
        { provide: ParentWindowToken, useValue: window },
        PostMessage,
        RecieveMessage,
        LocalStorageService,
        ParentSubcategoryAccordionService,
        ValidateFieldService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
