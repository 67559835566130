<mat-dialog-content class="accent-color">
  <div class="row expanded" style="display: block; padding-bottom: 10px">
    {{(confirmationPrompt | cfgLabel) || confirmationPrompt}}
  </div>
  <div class="row align-center" style="position:relative">
    <button mat-raised-button class="button u-no-margin radius" test-id="YES_BUTTON" (click)="clickYes()">
      {{'yes' | cfgLabel}}
    </button>
    <span style="padding-right: 10px"></span>
    <button mat-raised-button class="button u-no-margin radius" test-id="NO_BUTTON" (click)="clickNo()">
      {{'no' | cfgLabel}}
    </button>
  </div>
</mat-dialog-content>
