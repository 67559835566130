import {Component, Input, OnInit} from '@angular/core';
import {QuickConfigData} from '../domain/QuickConfigData';
import {Field} from '../domain/Field';
import {Choice} from '../domain/Choice';
import {GRAPHIC_SIZE, isOptionGraphicDisplay_Comment} from '../util/field-util';

@Component({
  selector: 'cfg-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
  @Input() content;
  @Input() quickConfigData: QuickConfigData;

  graphicSize = GRAPHIC_SIZE;
  textSize = {
    'SMALL': '.7em',
    'MEDIUM': '1em',
    'LARGE': '1.5em',
  };

  constructor() {
  }

  ngOnInit() {
  }

  getGraphicUrl(url) {
    return this.quickConfigData.imageOptionUrl + url;
  }

  displayTitle(): boolean {
    return !(this.content.menuType && this.content.menuType.includes('notitle'))
      && this.content.value && this.content.description;
  }

  sizeMap(size: string): string {
    if (size) {
      return this.textSize[size];
    }
  }

  alertStyles() {
    return {
      'color': this.content.styles && this.content.styles.color || 'red',
      'font-style': (this.content.styles && this.content.styles.italic) ? 'italic' : '',
      'font-weight': (this.content.styles && this.content.styles.bold) ? 'bold' : '',
      'font-size': this.content.styles && this.sizeMap(this.content.styles.size)
    };
  }

  isOptionGraphicDisplay_Comment(container: (Field | Choice)) {
    return isOptionGraphicDisplay_Comment(container);
  }
}
