<div [id]="parentSubcategory.id" class="scroll-adjust">
  <li
    [ngClass]="{'c-card accordion c-accordion': parentSubcatCard, 'accordion-item c-accordion__item ': !parentSubcatCard, 'is-active': isExpanded(parentSubcategory.id)}">
    <a [id]="parentSubcategory.id + '-title'" class="accordion-title c-accordion__title" style="font-size:1.25rem; padding:.5rem"
       (click)="toggleAccordion(parentSubcategory.id)">
      <img *ngIf="parentSubcategory.graphicIconUrl" src="{{getGraphicUrl(parentSubcategory.graphicIconUrl)}}"/>
      {{parentSubcategory.description}}
    </a>
    <div *ngIf="isExpanded(parentSubcategory.id)" [ngClass]="{'accordion-item c-accordion__item ': parentSubcatCard}"
         class="accordion-content c-accordion__content" style="display: block; position: relative"
         [ngStyle]="{'padding': !parentSubcategory.menuType || !parentSubcategory.menuType.includes('inline') ? '.5rem .5rem 0': ''}"
         data-tab-content>
      <ng-container *ngIf="hasInnerSubcats()">
        <div class="grid-container" [ngClass]="{'multi-column': !(isMobile()), 'single-column': (isMobile())}">
          <div *ngFor="let parentSubcat of parentSubcategory.subcategories; trackBy: trackBySubcat"
               class="grid-item"
               [ngClass]="['row-span-' + parentSubcat.rowSpan, 'column-span-' + parentSubcat.colSpan, 'row-' + parentSubcat.rowPos, 'column-' + parentSubcat.colPos, 'mobile-' + parentSubcat.mobilePos]">
            <cfg-inner-subcategory [skinnySubcat]="skinnySubcat"
                                   [subcategory]="parentSubcat" [userData]="userData"
                                   [quickConfigData]="quickConfigData"></cfg-inner-subcategory>
          </div>
        </div>
      </ng-container>
      <div class="grid-container" [ngClass]="{'multi-column': !(isMobile()), 'single-column': (isMobile())}">
        <div *ngFor="let field of parentSubcategory.fields; trackBy: trackByField"
             class="grid-item"
             [ngClass]="['row-span-' + field.rowSpan, 'column-span-' + field.colSpan, 'row-' + field.rowPos, 'column-' + field.colPos, 'mobile-' + field.mobilePos]">
          <cfg-field style=" width: 100%" [field]="field" [parentSubcatId]="parentSubcategory.id"
                     [userData]="userData" [quickConfigData]="quickConfigData"></cfg-field>
        </div>
      </div>
    </div>
  </li>
</div>


