import {Field} from '../domain/Field';
import {Choice} from '../domain/Choice';

export function isOptionGraphicDisplay_Comment(container: (Field | Choice)) {
  return container.optionGraphic && container.graphicDisplayLocation === 'COMMENT';
}

export function isOptionGraphicDisplay_Inline(container: (Field | Choice)) {
  return container.optionGraphic && container.graphicDisplayLocation !== 'COMMENT';
}

export const GRAPHIC_SIZE = {
  'SMALL': '40px',
  'MEDIUM': '80px',
  'ACTUAL': ''
};
