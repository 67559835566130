import {LocalStorageService} from 'angular-2-local-storage';
import {Inject, Injectable} from '@angular/core';
import {UrlUtil} from '../util/url-util';
import {propertiesSupport, setProperty} from '../properties/properties';

const INITIALIZE_ACCORDIONS_EXPANDED = true;

@Injectable()
export class ParentSubcategoryAccordionService {
  private orgFolderHome = UrlUtil.getOrgFolderHome();
  private accordionMode = propertiesSupport('accordionMode', false);
  private _initAccordionCollapsed: boolean;
  private expandedParentSubcat: string;

  constructor(@Inject(LocalStorageService) private localStorageService) {
    this.expandedParentSubcat = this.localStorageService.get('expandedParentSubcat');
    if (!!UrlUtil.getInitAccordionCollapsed()) {
      setProperty('initAccordionCollapsed', UrlUtil.getInitAccordionCollapsed());
    }
    this._initAccordionCollapsed = propertiesSupport('initAccordionCollapsed', false);
  }

  isExpanded(parentSubcategoryId: string) {
    return <boolean>this.localStorageService.get(this.getLocalStorageKey(parentSubcategoryId));
  }

  toggleAccordion(parentSubcategoryId: string) {
    const nextState = !this.isExpanded(parentSubcategoryId);
    this.setExpanded(parentSubcategoryId, nextState);
  }

  expandAll(parentSubcategoryIds: string[]) {
    parentSubcategoryIds.filter(id => !this.isExpanded(id))
      .forEach(id => this.toggleAccordion(id));
  }

  collapseAll(parentSubcategoryIds: string[]) {
    parentSubcategoryIds.filter(id => this.isExpanded(id))
      .forEach(id => this.toggleAccordion(id));
  }

  initAccordionItem(parentSubcategoryId: string) {
    if (this.isExpanded(parentSubcategoryId) == null) {
      this.setExpanded(parentSubcategoryId, !(this.accordionMode || this._initAccordionCollapsed));
    }
  }

  private setExpanded(parentSubcategoryId: string, expanded: boolean) {
    if (this.accordionMode && expanded) {
      if (this.expandedParentSubcat) {
        this.setExpanded(this.expandedParentSubcat, false);
      }
      this.expandedParentSubcat = parentSubcategoryId;
      this.localStorageService.set('expandedParentSubcat', this.expandedParentSubcat);
      const element = document.getElementById(parentSubcategoryId);
      setTimeout(() => element.scrollIntoView(), 0);
    }
    this.localStorageService.set(this.getLocalStorageKey(parentSubcategoryId), expanded);
  }

  private getLocalStorageKey(parentSubcategoryId: string) {
    return `${this.orgFolderHome}.${parentSubcategoryId}.accordion_expanded`;
  }

  set initAccordionCollapsed(value: boolean) {
    this._initAccordionCollapsed = value;
  }

  get initAccordionCollapsed(): boolean {
    return this._initAccordionCollapsed;
  }
}
