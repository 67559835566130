import {ParentWindowToken} from './injection-tokens';
import {UrlUtil} from './url-util';
import {ItemMultiplierComponent} from '../item-multiplier/item-multiplier.component';
import {QuickConfigData} from '../domain/QuickConfigData';
import {ParentSubcategoryAccordionService} from '../parent-subcategory-accordion/parent-subcategory-accordion.service';
import {MatDialog} from '@angular/material/dialog';
import {Inject, Injectable} from '@angular/core';
import {QuickConfigService} from '../api/quick-config.service';
import {Category} from '../domain/Category';
import {ProductOptionUtils} from './product-option-util';
import {PostMessage} from './post-message';
import {OptionOverrideComponent} from '../option-override/option-override.component';

@Injectable()
export class RecieveMessage {
  quickConfigData: QuickConfigData;
  displayingItemMultiplier = false;
  displayingItemOverride = false;

  constructor(@Inject(ParentWindowToken) private parentWindow, private accordionService: ParentSubcategoryAccordionService,
              private dialog: MatDialog, private quickConfigService: QuickConfigService, private postMessage: PostMessage) {
    if (parentWindow === window) {
      this.parentWindow = window.parent.window;
    }
    window.addEventListener('message', (event) => this.receiveMessage(this.accordionService, event), false);
  }

  updateQuickConfigData(quickConfigData: QuickConfigData) {
    this.quickConfigData = quickConfigData;
  }

  receiveMessage(accordionService: ParentSubcategoryAccordionService, event) {
    if (event.origin !== UrlUtil.getIFEDomain()) {
      return;
    }

    if (event.data === 'expandAll' && this.quickConfigData) {
      this.accordionService.expandAll(this.quickConfigData.allParentSubcatIds());
    }
    if (event.data === 'collapseAll' && this.quickConfigData) {
      this.accordionService.collapseAll(this.quickConfigData.allParentSubcatIds());
    }
    if (event.data === 'openItemMultiplier') {
      let offset = window.scrollY;
      offset = offset - (window.screenY - 50) / 2;
      if (offset < 50) {
        offset = 50;
      }
      if (!this.displayingItemMultiplier) {
        this.displayingItemMultiplier = true;
        const dialogRef = this.dialog.open(ItemMultiplierComponent, {
          panelClass: 'modal',
          position: {top: offset + 'px'},
          data: {
            discountList: this.quickConfigData.discounts,
            quickConfigData: this.quickConfigData
          }
        });
        dialogRef.afterClosed().subscribe(() => this.displayingItemMultiplier = false);
      }
    }
    if (event.data === 'openItemOverride' && this.quickConfigData) {
      let offset = window.scrollY;
      offset = offset - (window.screenY - 50) / 2;
      if (offset < 50) {
        offset = 50;
      }
      if (!this.displayingItemOverride) {
        this.displayingItemOverride = true;
        const dialogRef = this.dialog.open(OptionOverrideComponent, {
          panelClass: 'modal',
          position: {top: offset + 'px'},
          data: {
            choice: this.quickConfigData.product,
            isProduct: true,
            overridePrice: this.quickConfigData.userData.privileges['overridePrice'] || this.quickConfigData.userData.privileges['overrideRFQ'] && this.quickConfigData.product.rfq,
            overrideDescription: false,
            overrideQuantity: false,
            overrideLeadTime: false,
            overrideSource: false,
            viewListPrice: this.quickConfigData.userData.privileges['viewListPrice'],
            quickConfigService: this.quickConfigService,
            quickConfigData: this.quickConfigData,
            postMessageUtil: this.postMessage
          }
        });
        dialogRef.afterClosed().subscribe(() => this.displayingItemOverride = false);
      }
    }
    if (event.data === 'getSubcategories') {
      this.postMessage.publishSubcatListData(ProductOptionUtils.flattenSubcategories(this.quickConfigData));
    }
    if (event.data.message === 'createCustomOption') {
      this.quickConfigService.addCustomOption({
        id: event.data.subcategoryId,
        type: event.data.type,
        quickConfigData: this.quickConfigData,
        postMessageUtil: this.postMessage
      })
        .subscribe((res: { categories: Category[], extendedSellPrice: string }) => {
          this.quickConfigData.updateCategories(res.categories, res.extendedSellPrice);
        });
    }
  }
}
