import {Injectable} from '@angular/core';
import {QuickConfigData} from '../domain/QuickConfigData';
import {isNullOrUndefined} from '../util/js-util';

@Injectable()
export class ValidateFieldService {

  allValid = true;
  pendingInvalid: any[];
  groupingSymbol: string;
  decimalSymbol: string;
  quickConfigData: QuickConfigData;

  constructor() {
    this.pendingInvalid = [];
  }

  setQuickConfigInitData(quickConfigInitData) {
    this.quickConfigData = quickConfigInitData;
    this.groupingSymbol = quickConfigInitData.userData.groupingSymbol;
    this.decimalSymbol = quickConfigInitData.userData.decimalSymbol;
    return quickConfigInitData;
  }

  validateFields(response) {
    this.quickConfigData = QuickConfigData.create(response);
    if (response.categories) {
      response.categories.forEach(category =>
        category.parentSubcategories.forEach(parentSubcat =>
          this.flattenFields(parentSubcat).forEach(pair => {
            const field = pair[1];
            this.checkPending(pair);
            field.invalid = this.numericInvalid(field);
          })));
    }
    return response;
  }

  flattenFields(parentSubcat) {
    return parentSubcat.subcategories
      .map(subcat => subcat.fields.map(field => [subcat.id, field])).concat([[]])
      .reduce((list, fields) => list.concat(fields)) // todo - list is init to the 1st element, would that cause a duplicate?
      .concat(parentSubcat.fields.map(field => [null, field]));
  }

  updatePending(params) {
    this.splicePending(params);
    this.pendingInvalid.push(params);
  }

  splicePending(params) {
    this.pendingInvalid.forEach(field => {
      if (field[0] === params[0]) {
        this.pendingInvalid.splice(this.pendingInvalid.indexOf(field), 1);
      }
    });
  }

  checkPending(pair) {
    let field, subcatId = null;
    if (Array.isArray(pair)) {
      [subcatId, field] = pair;
    } else {
      field = pair;
    }
    const changeParams = [];
    this.pendingInvalid.forEach(pending => {
      if (pending[0] === field.httpNumericValueName) {
        field.value = pending[1];
        field.numericValue = this.parseValue(pending[1], field.numericValue);
        this.splicePending(pending);
        if (!this.numericInvalid(field)) {
          changeParams.push(pending);
        } else {
          this.updatePending(pending);
          changeParams.push(pending);
        }
      }
    });
    if (changeParams.length > 0) {
      field.updates = changeParams;
    }
  }

  validateParameters(changeParams) {
    this.allValid = true;
    if (this.includesUomChange(changeParams)) {
      this.numericParams(changeParams).forEach(param => this.updatePending(param));
      return true;
    }
    changeParams.forEach(param => {
      this.splicePending(param);
      this.quickConfigData.categories.forEach(category =>
        category.parentSubcategories.forEach(parentSubcat =>
          this.flattenFields(parentSubcat)
            .filter(pair => {
              const field = pair[1];
              return param[0] === field.httpNumericValueName;
            })
            .forEach(pair => {
              const field = pair[1];
              if (this.numericInvalid(field, param)) {
                this.updatePending(param);
              }
            })
        ));
    });
    return this.allValid;
  }

  private includesUomChange(params) {
    let uomChange = false;
    params.forEach(param => {
      if (param[0].includes('inputUnit')) {
        uomChange = true;
      }
    });
    return uomChange;
  }

  // todo send in the field type
  private numericParams(params) {
    return params.filter(param => param[0].includes('numeric'));
  }

  // todo extract conditionals from prop setting and opening
  public numericInvalid(field, param?) {
    let invalid;
    const value = param ? this.parseValue(param[1], field.numericValue) : field.numericValue;
    if (field.displayType === 'NUMERIC_INPUT' || field.displayType === 'NUMERIC_SPINNER') {

      if (this.isBounded(field)
        && (value < field.minValue || value > field.maxValue)
        && this.notClose(field, value, param)) {
        invalid = true;
        this.allValid = false;
        field.invalid = true;
      } else {
        invalid = false;
        field.invalid = false;
      }
    }
    return invalid;
  }

  isBounded(field): boolean {
    return (!isNullOrUndefined(field.minValue) && !(field.minValue === 0 && field.maxValue === 0));
  }

  // todo - this has a side effect, it modifies the param[1]
  // todo - rename?
  private notClose(field, value, param?): boolean {
    if (param) {
      if (this.getPrecisionTrimmed(field, value) === this.getPrecisionTrimmed(field, field.minValue)) {
        param[1] = '' + field.minValue;
        return false;
      } else if (this.getPrecisionTrimmed(field, value) === this.getPrecisionTrimmed(field, field.maxValue)) {
        param[1] = '' + field.maxValue;
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private getPrecisionTrimmed(field, value) {
    if (field.unitOfMeasureField) {
      if (value < field.unitOfMeasureField.decimalLimitBaseValue) {
        return value.toFixed(field.unitOfMeasureField.precisionBelowBaseValue);
      } else {
        return value.toFixed(field.unitOfMeasureField.precisionAboveBaseValue);
      }
    }
    return value;
  }

  public parseValue(value: string, failVal): number {
    if (this.decimalSymbol && this.groupingSymbol) {
      value = value.replace(this.groupingSymbol, '');
      value = value.replace(this.decimalSymbol, '.');
      return parseFloat(value);
    }
    return failVal;
  }

}
