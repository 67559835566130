import {Component, OnInit} from '@angular/core';
import {QuickConfigService} from './api/quick-config.service';
import {QuickConfigData} from './domain/QuickConfigData';
import {PostMessage} from './util/post-message';
import {UrlUtil} from './util/url-util';
import urlJoin from 'proper-url-join';
import {UserData} from './domain/UserData';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';
import {RecieveMessage} from './util/recieve-message';
import {LocalStorageService} from 'angular-2-local-storage';
import {LabelService} from './label/label.service';

@Component({
  selector: 'cfg-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  quickConfigData: QuickConfigData;
  userData: UserData;

  constructor(private initService: QuickConfigService,
              private postMessage: PostMessage,
              private recieveMessage: RecieveMessage,
              private angulartics2: Angulartics2GoogleAnalytics,
              private localStorage: LocalStorageService,
              private labelService: LabelService) {
  }

  ngOnInit() {
    this.initService.setupSessionCookieAcrossDomains().subscribe(
      () => {
        this.initService.init().subscribe(
          quickConfigInitData => {
            this.labelService.initialize(quickConfigInitData.labels);
            this.setQuickConfigInitData(quickConfigInitData);
            this.publishMessages(quickConfigInitData);
            if (quickConfigInitData.hasKnowledgeBaseChanged && !this.localStorage.get(quickConfigInitData.itemId)) {
              let knowledgeBaseChangedMessage;
              knowledgeBaseChangedMessage = quickConfigInitData.labels['knowledgebaseChanged'] + '\n\n'
                + quickConfigInitData.labels['knowledgebaseChanged2'];
              alert(knowledgeBaseChangedMessage);
              this.localStorage.add(quickConfigInitData['itemId'], 15);
            }
          },
          error => console.error('error during init', error)
        );
      }
    );
  }

  private publishMessages(quickConfigInitData) {
    this.postMessage.publishPriceMessage(quickConfigInitData.price);
    this.postMessage.publishLeadTimeMessage(quickConfigInitData.leadTimeTotal);
    this.postMessage.publishLoadedMessage();
    this.postMessage.displaySpecialOptionButton(
      quickConfigInitData.categories.reduce((result, category) => result.concat(category.parentSubcategories), []));
  }

  setQuickConfigInitData(initData: QuickConfigData) {
    this.quickConfigData = initData;
    this.userData = initData.userData;
    this.recieveMessage.updateQuickConfigData(this.quickConfigData);
  }

  getCssPath(): string {
    const cssUrlParam = UrlUtil.getCssPath();
    if (cssUrlParam != null) {
      return cssUrlParam;
    }
    const orgFolderHomeQueryParam = UrlUtil.getOrgFolderHome();
    const orgFolderHome = orgFolderHomeQueryParam == null ? 'acme' : orgFolderHomeQueryParam;
    return UrlUtil.getOrgUnitFolderHome() ?
      urlJoin(UrlUtil.getIFEDomain(), 'config', orgFolderHome, UrlUtil.getOrgUnitFolderHome(), 'css') :
      urlJoin(UrlUtil.getIFEDomain(), 'config', orgFolderHome, 'css');
  }

  trackByCategory(index, category) {
    return category ? category.id : undefined;
  }
}
