import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpParams} from '@angular/common/http';
import {SubscribeActionProcessor} from '../util/subscribe-action-processor';
import {QuickConfigService} from '../api/quick-config.service';
import {PostMessage} from '../util/post-message';
import {QuickConfigData} from '../domain/QuickConfigData';
import {Category} from '../domain/Category';
import {isNullOrUndefined} from '../util/js-util';
import {HttpFormUrlEncodingCodec} from '../util/http-form-url-encoding-codec';

@Component({
  selector: 'cbs-option-override',
  templateUrl: './option-override.component.html',
  styles: [
      `:host ::ng-deep .mat-form-field-label {
      padding: 0 16px;
    }

    :host ::ng-deep .mat-form-field-suffix {
      max-width: 50%;
    }

    div.disabled-appearance {
      padding: 0.4375rem 3px;
      vertical-align: middle;
      line-height: 24px;
    }`
  ]
})
export class OptionOverrideComponent {

  parentSubcategoryId: number;
  choice;
  overrideDescriptionPrivilege: boolean;
  overridePricePrivilege: boolean;
  description: string;
  price: number | string;
  quantity: number;
  postMessageUtil: PostMessage;
  quickConfigService: QuickConfigService;
  quickConfigData: QuickConfigData;
  leadTime;
  source: string;
  showCus: boolean;
  viewListPrice: boolean;
  optional: boolean;
  isCustomOptionInit: boolean;
  overrideQuantity: boolean;
  overrideLeadTime: boolean;
  overrideSource: boolean;
  isProduct: boolean;

  constructor(public dialogRef: MatDialogRef<OptionOverrideComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.choice = data.choice;
    this.parentSubcategoryId = data.parentSubcatId;
    this.overrideDescriptionPrivilege = data.overrideDescription;
    this.overridePricePrivilege = data.overridePrice && this.isEditablePriceType(this.choice);
    this.viewListPrice = data.viewListPrice;
    this.description = this.choice.description;
    this.price = this.choice.price && 'rfq' !== this.choice.price && this.isEditablePriceType(this.choice)
      ? this.choice.priceValue : '';
    this.quickConfigService = this.data.quickConfigService;
    this.postMessageUtil = this.data.postMessageUtil;
    this.quickConfigData = this.data.quickConfigData;
    this.quantity = this.choice.quantity;
    this.leadTime = this.isEditablePriceType(this.choice) ? this.choice.leadTime : '';
    this.source = this.choice.source;
    this.showCus = this.choice.showCus;
    this.optional = this.choice.optional;
    this.isCustomOptionInit = data.isCustomOptionInit;
    this.isProduct = data.isProduct;

    // can override these by default
    this.overrideQuantity = data.overrideQuantity !== false;
    this.overrideLeadTime = data.overrideLeadTime !== false;
    this.overrideSource = data.overrideSource !== false;
  }

  update() {
    this.submitChange();
  }

  submitChange() {
    if (!isNullOrUndefined(this.choice.displayLoading)) {
      this.choice.displayLoading = true;
    }

    let urlParams = new HttpParams({encoder: new HttpFormUrlEncodingCodec()});
    const paramSuffix = this.choice.httpValue || this.choice.id;
    urlParams = urlParams.set('description_' + paramSuffix, this.description);
    urlParams = urlParams.set('basePriceOverride_' + paramSuffix, '' + this.price);
    if (this.price !== '') {
      urlParams = urlParams.set('priceCalcMethod_' + paramSuffix, '' + 'EnterPrice');
      urlParams = urlParams.set('ovrSourceCurrency_' + paramSuffix, '' + this.choice.currencyCode);
    }
    urlParams = urlParams.set('quantity_' + paramSuffix, '' + this.quantity);
    urlParams = urlParams.set('source_' + paramSuffix, '' + this.source);
    urlParams = urlParams.set('leadTime_' + paramSuffix, '' + this.leadTime);
    urlParams = urlParams.set('showCus_' + paramSuffix, '' + this.showCus);
    urlParams = urlParams.set('selectionType_' + paramSuffix, this.optional ? 'optional' : 'primary');

    this.quickConfigService.submitFieldChange('' + this.parentSubcategoryId, urlParams)
      .subscribe(res => {
        new SubscribeActionProcessor(this.postMessageUtil).processOptionChange(res, this.quickConfigData);
      });
  }

  isEditablePriceType(option) {
    return !(option.price === 'noCharge' || option.price === 'included');
  }

  toggleShowCus() {
    this.showCus = !this.showCus;
  }

  descriptionExists() {
    return !isNullOrUndefined(this.choice.description);
  }

  updateIncluded(value) {
    this.optional = value;
  }

  deleteNewCustomField() {
    if (this.isCustomOptionInit) {
      this.quickConfigService.deleteCustomOption(this.choice)
        .subscribe((res: { categories: Category[], extendedSellPrice: string, leadTimeTotal: string }) => {
          this.quickConfigData.updateCategories(res.categories, res.extendedSellPrice);
          this.postMessageUtil.publishPriceMessage(res.extendedSellPrice);
          this.postMessageUtil.publishLeadTimeMessage(res.leadTimeTotal);
        });
    }
  }

  isSelected(option) {
    if (isNullOrUndefined(option.selected)) {
      return true;
    }
    return option.selected;
  }
}
