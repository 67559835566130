import {Injectable} from '@angular/core';
import {Labels} from '../domain/Labels';

@Injectable({providedIn: 'root'})
export class LabelService {
  labels: Labels = {};

  public initialize(labels: Labels) {
    this.labels = labels;
  }

  public get(key: string): string {
    return this.labels[key];
  }
}
