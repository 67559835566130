import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PostMessage} from '../util/post-message';
import {QuickConfigService} from '../api/quick-config.service';
import {SubscribeActionProcessor} from '../util/subscribe-action-processor';
import {QuickConfigData} from '../domain/QuickConfigData';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'cfg-item-multiplier',
  templateUrl: './item-multiplier.component.html',
  styles: [
      `:host ::ng-deep .mat-form-field-label {
      padding: 0 16px;
    }

    :host ::ng-deep .mat-form-field-wrapper {
      padding: 0;
      border: 1px solid black;
    }

    :host ::ng-deep .mat-form-field-underline {
      display: none;
    }

    :host ::ng-deep .mat-form-field-suffix {
      width: auto;
    }

    :host ::ng-deep .mat-form-field-flex {
      padding: 0 .25rem;
    }

    th, td {
      border: 1px solid black;
      background-color: #e5e5e5;
    }

    table {
      border: 3px solid black;
    }`
  ]
})
export class ItemMultiplierComponent implements OnInit {

  displayMargin: boolean;
  discountObj: Discount;
  discountChangeList: DiscountChange[] = [];
  quickConfigData: QuickConfigData;
  preventClose = false;
  isLoading = false;
  editSellMultiplierPrivilege: boolean;
  editResellerMarginPrivilege: boolean;

  constructor(public dialogRef: MatDialogRef<ItemMultiplierComponent>, private snackBar: MatSnackBar,
              private postMessage: PostMessage, private quickConfigService: QuickConfigService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.discountObj = data.discountList;
    this.quickConfigData = data.quickConfigData;
    this.editSellMultiplierPrivilege = data.quickConfigData.userData.privileges.editSellMultiplier;
    this.displayMargin = data.quickConfigData.userData.privileges.displayResellerMargin;
    this.editResellerMarginPrivilege = data.quickConfigData.userData.privileges.editResellerMargin;
  }

  ngOnInit(): void {
  }

  is3Col(discountObj: Discount) {
    let is3Col = false;
    discountObj.discountIDList.forEach(id => {
      if (discountObj.discountList[id].editColumn > 0) {
        is3Col = true;
      }
    });
    return is3Col;
  }

  filteredColumn(discountObj: Discount, column?: number): any[] {
    const discountColumn = [];
    discountObj.discountIDList.forEach(id => {
      if (discountObj.discountList[id].editColumn === column || !column) {
        discountColumn.push(discountObj.discountList[id]);
      }
    });
    return discountColumn;
  }

  groupIdList(columnList: any[]): string[] {
    const groupNameList = [];
    columnList.forEach(discount => {
      if (!(groupNameList.indexOf(discount.groupName) >= 0)) {
        groupNameList.push(discount.groupName);
      }
    });
    return groupNameList;
  }

  discountSubList(columnList: any[], groupId: string): any[] {
    return columnList.filter(discount => discount.groupName === groupId);
  }

  updateMargin(discount, value: string) {
    this.discountChangeList.push({discountId: discount.objectID, updateValue: (+value) / 100, updateAttribute: 'OvrDistMargin'});
  }

  updateDiscount(discount, value: number) {
    if (discount.discountMinLimit > value || discount.discountMaxLimit < value) {
      this.preventClose = true;
      setTimeout(() => this.preventClose = false, 2000);
      this.snackBar.open('Discount for ' + discount.description + ' must be between ' +
        discount.discountMinLimit + ' and ' + discount.discountMaxLimit, null, {
        duration: 2000,
        verticalPosition: 'top'
      });
    } else {
      this.discountChangeList.push({discountId: discount.objectID, updateValue: +value, updateAttribute: 'OvrDiscount'});
    }
  }

  determineDiscountValue(discount: any) {
    if (discount.itemDiscount > 0) {
      return discount.itemDiscount;
    }
    return this.discountDefaultDisplay(discount);
  }

  discountDefaultDisplay(discount): number {
    return discount.quoteDiscount > 0 ? discount.quoteDiscount : discount.discount;
  }

  isEditable(discount: any) {
    return this.editSellMultiplierPrivilege && (!discount.limitsRange || (discount.limitsRange.min !== discount.limitsRange.max));
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  updateOverrideType(discount, value: boolean, overrideInputValue) {
    if (!value) {
      this.updateDiscount(discount, discount.discount);
    } else {
      this.updateDiscount(discount, overrideInputValue);
    }
    this.discountChangeList.push({discountId: discount.objectID, updateValue: +value, updateAttribute: 'OvrType'});
  }

  saveAndClose() {
    // todo
    setTimeout(() => {
      if (!this.preventClose) {
        this.quickConfigService.submitDiscountChange(this.discountChangeList).subscribe(res => {
          new SubscribeActionProcessor(this.postMessage).processOptionChange(res, this.quickConfigData);
        });
        this.dialogRef.close();
      }
    }, 100);
  }

  apply() {
    this.isLoading = true;
    setTimeout(() => {
      if (!this.preventClose) {
        this.quickConfigService.submitDiscountChange(this.discountChangeList).subscribe(res => {
          new SubscribeActionProcessor(this.postMessage).processOptionChange(res, this.quickConfigData);
          this.isLoading = false;
        });
      }
    }, 100);
  }

  resetDiscounts() {
    this.discountChangeList = [];
    return;
  }
}

interface Discount {
  discountFactorMax: number;
  discountFactorMin: number;
  discountIDList: string[];
  discountList: any;
}

interface DiscountChange {
  discountId: string;
  updateValue: number;
  updateAttribute: string;
}
