import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import urlJoin from 'proper-url-join';

@Component({
  selector: 'cfg-global-css',
  template: `
    <link rel="stylesheet" [href]="ifeMaterialUrl">
    <link rel="stylesheet" [href]="ifeBeagleUrl">`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class GlobalCssComponent implements OnInit {

  @Input() cssPath: string;
  ifeBeagleUrl: SafeResourceUrl;
  ifeMaterialUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.ifeBeagleUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlJoin(this.cssPath, 'ife-beagle.css'));
    this.ifeMaterialUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlJoin(this.cssPath, 'ife-material.css'));
  }

  getSanitizedCssUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.cssPath);
  }

}
