import {InjectionToken} from '@angular/core';
/*
InjectionToken is used for the parent window object so that it can be mocked out as required by tests.
 Note: cannot move the below two declarations to app.module.ts else it creates cyclic dependencies which are crazy to figure out.
 */

export const ParentWindowToken = new InjectionToken('ParentWindow');

export function _parentWindow(): Window {
  return parent;
}

export const mockParentWindow: any = {
  postMessage: function () {
  }
};

