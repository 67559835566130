import {Injectable} from '@angular/core';
import {UrlUtil} from '../util/url-util';
import {ApiUtil} from '../util/api-util';
import {QuickConfigData} from '../domain/QuickConfigData';
import {FieldChangeResponse} from './field-change-response';
import {Angulartics2} from 'angulartics2';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import urlJoin from 'proper-url-join';
import {ValidateFieldService} from './validate-field.service';
import {ProductOptionUtils} from '../util/product-option-util';
import {OptionOverrideComponent} from '../option-override/option-override.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class QuickConfigService {
  displayDiscounts = false;

  constructor(private httpClient: HttpClient, private angulartics2: Angulartics2,
              private validateFieldService: ValidateFieldService, private dialog: MatDialog) {
  }

  init(): Observable<QuickConfigData> {
    return this.httpClient.get(UrlUtil.getConfigInitUrl(), {withCredentials: true}).pipe(
      map(data => this.validateFieldService.validateFields(data)),
      map(QuickConfigData.create),
      map(quickConfigData => this.validateFieldService.setQuickConfigInitData(quickConfigData)),
      catchError(ApiUtil.handleError));
  }

  setupSessionCookieAcrossDomains(): Observable<any> {
    return this.httpClient.get(UrlUtil.getSessionCookieInitUrl(), {withCredentials: true, observe: 'response'});
  }

  submitFieldChange(parentSubcatId: string, params: HttpParams): Observable<FieldChangeResponse> {
    this.angulartics2.eventTrack.next({action: 'field change', properties: {category: UrlUtil.getIFEDomain()}});
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    return this.httpClient
      .post<FieldChangeResponse>(UrlUtil.getOptionSubmitUrl(parentSubcatId), params, options).pipe(
        map(data => <FieldChangeResponse>this.validateFieldService.validateFields(data)));
  }

  submitDiscountChange(payload) {
    this.angulartics2.eventTrack.next({action: 'discounts change', properties: {category: UrlUtil.getIFEDomain()}});
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    const updateDiscountUrl = urlJoin(UrlUtil.getIFEDomain(), '/config/api/v1/quickconfig/updateDiscount');
    let urlParams = new HttpParams();
    payload.forEach(discount => {
      urlParams = urlParams.set(discount.discountId + '-' + discount.updateAttribute, discount.updateValue);
    });
    return this.httpClient
      .post(updateDiscountUrl, urlParams, options).pipe(
        map(data => <FieldChangeResponse>data));
  }

  addCustomOption(payload) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('subcategoryId', payload.id);
    urlParams = urlParams.set('specialOptionType', payload.type);
    return this.httpClient
      .post(urlJoin(UrlUtil.getIFEDomain(), '/config/api/v1/cbs/addCustomOption'), urlParams, options).pipe(
        map(data => {
          const fieldData = ProductOptionUtils.findFieldInCategoriesWithData(data['newSpecialId'], data['categories']);
          if (!!fieldData) {
            this.dialog.open(OptionOverrideComponent, {
              panelClass: 'modal',
              position: {top: 50 + 'px'},
              data: {
                choice: fieldData.field,
                parentSubcatId: fieldData.parentSubcatId,
                subcatId: fieldData.subcatId,
                categoryId: fieldData.categoryId,
                fieldId: fieldData.field.id,
                overridePrice: payload.quickConfigData.userData.privileges['overridePrice'] ||
                  payload.quickConfigData.userData.privileges['overrideRFQ'] && fieldData.field.rfq,
                overrideDescription: true,
                viewListPrice: true,
                quickConfigService: this,
                postMessageUtil: payload.postMessageUtil,
                quickConfigData: payload.quickConfigData,
                isCustomOptionInit: true
              }
            });
          } else {
            alert('Field not created properly, update knowledge base to support more custom options');
          }
          return data;
        }));
  }

  deleteCustomOption(field) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('fieldId', field.id);
    urlParams = urlParams.set('categoryId', field.categoryId);
    return this.httpClient
      .post(urlJoin(UrlUtil.getIFEDomain(), '/config/api/v1/cbs/deleteCustomOption'), urlParams, options).pipe(
        map(data => data));
  }
}
